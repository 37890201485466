import React from 'react';

function MacroRegion() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      fill="none"
      viewBox="0 0 12 11"
    >
      <path
        fill="#632956"
        d="M6 0l1.763 3.573 3.943.573-2.853 2.781.674 3.927L6 9l-3.527 1.854.674-3.927L.294 4.146l3.943-.573L6 0z"
      ></path>
    </svg>
  );
}

export default MacroRegion;
