import React from 'react';

function People() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      fill="none"
      viewBox="0 0 34 35"
    >
      <path
        fill="#CED4DA"
        fillRule="evenodd"
        d="M31.875 30.625s2.125 0 2.125-2.188c0-2.187-2.125-8.75-10.625-8.75S12.75 26.25 12.75 28.438c0 2.188 2.125 2.188 2.125 2.188h17zm-16.99-2.065v-.004.004zm.037-.122h16.906a.594.594 0 00.03-.005l.017-.004c-.004-.578-.355-2.253-1.615-3.763-1.173-1.415-3.234-2.791-6.885-2.791-3.649 0-5.71 1.378-6.885 2.791-1.26 1.51-1.613 3.187-1.615 3.763l.047.009zm16.942.122v-.004.004zm-8.489-13.247a4.19 4.19 0 003.005-1.282 4.441 4.441 0 001.245-3.094c0-1.16-.448-2.273-1.245-3.093a4.19 4.19 0 00-3.005-1.282 4.19 4.19 0 00-3.005 1.282 4.441 4.441 0 00-1.245 3.093c0 1.16.448 2.274 1.245 3.094a4.19 4.19 0 003.005 1.281zm6.375-4.376a6.73 6.73 0 01-.485 2.512c-.32.796-.79 1.52-1.382 2.129A6.366 6.366 0 0125.815 17a6.217 6.217 0 01-4.88 0 6.366 6.366 0 01-2.068-1.422 6.577 6.577 0 01-1.382-2.13 6.73 6.73 0 01-.485-2.51c0-1.741.672-3.41 1.867-4.64a6.284 6.284 0 014.508-1.923c1.69 0 3.312.691 4.508 1.922a6.661 6.661 0 011.867 4.64zM14.739 20.3a12.182 12.182 0 00-2.614-.54c-.498-.05-1-.073-1.5-.073C2.125 19.688 0 26.25 0 28.438c0 1.46.708 2.188 2.125 2.188h8.959a5.018 5.018 0 01-.459-2.188c0-2.209.801-4.466 2.316-6.352A11.4 11.4 0 0114.74 20.3zm-4.284 1.575c-3.545.044-5.557 1.4-6.715 2.791-1.269 1.518-1.615 3.2-1.615 3.771H8.5c0-2.285.686-4.563 1.955-6.562zm-7.268-9.844c0-1.74.672-3.41 1.868-4.64a6.284 6.284 0 014.508-1.922c1.69 0 3.312.691 4.507 1.922a6.662 6.662 0 011.867 4.64c0 1.74-.671 3.41-1.867 4.64a6.284 6.284 0 01-4.508 1.923 6.284 6.284 0 01-4.507-1.922 6.662 6.662 0 01-1.867-4.64zm6.376-4.375a4.19 4.19 0 00-3.006 1.282 4.441 4.441 0 00-1.245 3.093 4.44 4.44 0 001.245 3.094 4.19 4.19 0 003.005 1.281 4.19 4.19 0 003.006-1.281 4.441 4.441 0 001.245-3.094c0-1.16-.448-2.273-1.245-3.093a4.19 4.19 0 00-3.005-1.282z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default People;
